import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Container from '@objects/container'
import Carousel from '@objects/carousel'
import Image from '@objects/image'

const useStyles = makeStyles((theme) => ({
  galleryRoot: {
    backgroundColor: theme.palette.background.grey,
    padding: theme.spacing(8, 0, 10, 0),
    margin: theme.spacing(15, 0),
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(10, 0),
      margin: theme.spacing(25, 0),
    },
  },
  slides: {},
  galleryImage: {
    margin: 0,
  },
  pagination: {
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(0, 38),
    },
  },
  carousel: {
    margin: theme.spacing(6, -4, 0, -4),
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      margin: theme.spacing(10, -23, 0, -38),
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 2,
        width: theme.spacing(23),
      },
      '&:before': {
        left: 0,
        background: `linear-gradient(to right, ${theme.palette.background.grey}, transparent 100%)`,
      },
      '&:after': {
        right: 0,
        background: `linear-gradient(to left, ${theme.palette.background.grey}, transparent 100%)`,
        pointerEvents: 'none',
      },
    },
  },
  carouselRight: {
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(10, -38, 0, -23),
    },
  },
  hlRight: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(15),
    },
  },
}))

function GalleryCarousel({ className, headline, items, sidebar }) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={clsx(className, classes.galleryRoot)}>
      <Container
        sidebar={sidebar}
        width={sidebar ? 'lg' : 'sm'}
        ariaLabel="arialabel.gallerycarousel"
      >
        <Headline level={3} className={clsx({ [classes.hlRight]: sidebar })}>
          {headline}
        </Headline>
        <div
          className={clsx(classes.carousel, {
            [classes.carouselRight]: sidebar,
          })}
        >
          <Carousel
            pagination="fraction"
            settings={{
              breakpoints: {
                [theme.breakpoints.values.lg]: {
                  slidesPerView: 1.51,
                  spaceBetween: 32,
                  centeredSlides: true,
                },
                [parseInt(theme.container.lg, 10)]: {
                  slidesPerView: 1.45,
                  spaceBetween: 32,
                  centeredSlides: true,
                },
              },
            }}
          >
            {items.map((item) => (
              <div className={classes.slides} key={item}>
                <Image className={classes.galleryImage} {...item} fullwidth />
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  )
}

GalleryCarousel.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      copyright: PropTypes.string,
      description: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  sidebar: PropTypes.bool,
}

export default GalleryCarousel
