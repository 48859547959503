import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Img from 'gatsby-image/withIEPolyfill'
import Grid from '@material-ui/core/Grid'

import Headline from '@objects/headline'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  imageText: {
    margin: theme.spacing(15, 0),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(20, 0),
    },
  },
  headline: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
    },
  },
  copy: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(10),
      },
    },
  },
  copyright: {
    ...theme.typography.info,
    margin: theme.spacing(2, 0, 0, 0),
    textAlign: 'right',
  },
}))

function ImageText({ direction, image, headline, id, copy }) {
  const classes = useStyles()

  return (
    <div className={classes.imageText}>
      {headline && (
        <Headline id={id} level={2} className={classes.headline}>
          {headline}
        </Headline>
      )}
      <Grid
        container
        spacing={8}
        direction={direction === 'Image right' ? 'row' : 'row-reverse'}
      >
        <Grid item xs={12} md={9}>
          {copy &&
            copy.map((paragraph, i) => {
              return (
                <Copy key={i} className={classes.copy} parseGlossary>
                  {paragraph}
                </Copy>
              )
            })}
        </Grid>
        {image && (
          <Grid item xs={12} md={3}>
            <Img
              className={classes.image}
              fluid={{ ...image.fluid, media: `(min-width: 0px)` }}
              alt={image.alt}
            />
            {image.copyright && (
              <p className={classes.copyright}>{image.copyright}</p>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  )
}

ImageText.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  direction: PropTypes.string,
  id: PropTypes.string,
  copy: PropTypes.array,
  image: PropTypes.shape({
    fluid: PropTypes.object,
    alt: PropTypes.string,
    copyright: PropTypes.string,
  }),
}
export default ImageText
