import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ContentfulComponent from './ContentfulComponent'
import Container from '@objects/container'

function ContentfulComponentArray({ className, componentarray, toc, faq }) {
  function renderComponents() {
    const containerArray = []
    let index = -1

    componentarray.forEach((component, i) => {
      const fullwidth =
        component.nodeType === 'blockquote' ||
        (component.nodeType === 'embedded-entry-block' &&
          component.data.target?.sys?.contentType?.sys?.id ===
            'galleryCarousel')

      if (i === 0 || fullwidth || containerArray[index]?.type === 'fullwidth') {
        index++
        containerArray.push({
          type: fullwidth ? 'fullwidth' : 'content',
          children: [],
        })
      }
      containerArray[index].children.push(
        <ContentfulComponent
          key={`${component.nodeType}J${i}`}
          className={className}
          component={component}
          sidebar={toc && fullwidth ? true : null}
          faq={faq}
        />
      )
    })

    return containerArray.map((group, index) => {
      if (group.type === 'content') {
        return (
          <Container
            key={`container-${index}`}
            type="article"
            width={toc ? 'lg' : 'sm'}
            sidebar={toc ? true : null}
          >
            {group.children}
          </Container>
        )
      } else {
        return <Fragment key={`container-${index}`}>{group.children}</Fragment>
      }
    })
  }
  return <>{renderComponents()}</>
}

ContentfulComponentArray.propTypes = {
  className: PropTypes.string,
  componentarray: PropTypes.array.isRequired,
  toc: PropTypes.bool,
  faq: PropTypes.bool,
}

export default ContentfulComponentArray
