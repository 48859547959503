import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { findParser } from './componentParser'

function ContenfulComponent({ component, sidebar, faq }) {
  const intl = useIntl()

  function renderComponent() {
    const Parser = findParser(component)

    if (!Parser) {
      if (typeof window !== 'undefined') {
        console.warn(
          'Component not defined: ',
          component,
          component.data.target?.sys?.contentType?.sys?.id
        )
      }
      return null
    }

    return React.createElement(
      Parser.component,
      Parser.props(component, intl.locale, sidebar, faq)
    )
  }
  return <>{renderComponent()}</>
}

ContenfulComponent.propTypes = {
  component: PropTypes.object.isRequired,
  sidebar: PropTypes.bool,
  faq: PropTypes.bool,
}

export default ContenfulComponent
