import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import clsx from 'clsx'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'

import ContentfulComponentArray from '@tools/ContentfulComponentArray'
import Headline from '@objects/headline'
import Icon from '@objects/icon'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  faqRoot: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(15),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(20),
      },
    },
    '& .MuiAccordion-root': {
      borderRadius: '1rem',
      marginTop: theme.spacing(6),

      '&::before': {
        display: 'none',
      },
      '&.Mui-expanded': {},
    },
    '& .MuiAccordionSummary-root': {
      borderRadius: '1rem',
      backgroundColor: theme.palette.background.grey,
      fontSize: '18px',
      lineHeight: '23px',
      paddingLeft: theme.spacing(8),
      transition: 'all 0.2s linear',
      '&.Mui-expanded': {
        color: theme.palette.default.main,
        background: theme.palette.red.main,
        borderRadius: '1rem 1rem 0 0',
        minHeight: 'auto',
        '@supports (min-height: initial)': {
          minHeight: 'initial',
        },
      },
      '&:focus, &.Mui-focused': {
        background: theme.palette.background.focus,
        color: theme.palette.text.invert,
      },
    },
    '& .MuiAccordionSummary-content': {
      '&.Mui-expanded': {
        margin: theme.spacing(4, 0),
      },
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: 'inherit',
      marginRight: '-8px',
    },
    '& .MuiAccordionDetails-root': {
      display: 'block',
      padding: theme.spacing(5, 8, 5, 8),
      fontSize: '16px',
      lineHeight: '22px',
      borderBottom: `${theme.spacing(4)}px solid ${theme.palette.red.main}`,
      borderRadius: theme.spacing(0, 0, 4, 4),

      $quoteRoot: {
        marginTop: '0',
        paddingTop: '0 !important',
      },
    },
    '& :focus + .MuiCollapse-entered .MuiAccordionDetails-root': {
      borderColor: theme.palette.background.focus,
    },
  },
  categoryHeadline: {
    ...theme.typography.h3,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  glossaryHeadline: {
    ...theme.typography.h2,
    marginBottom: theme.spacing(10),
    color: theme.palette.red.main,
  },
  accordionControlIcon: {
    marginRight: '2px',
  },
  quoteRoot: {
    marginTop: '0',
    paddingTop: '0 !important',
  },
}))

function renderSection(sectionData, handleChangeCb, expanded, classes, type) {

  const sortedsectionData = sectionData.sort((a, b) => a.id.localeCompare(b.id));

  return sortedsectionData.map(
    (
      {
        id,
        question: faqQuestion,
        answer: faqAnswer,
        glossaryExcludes,
      },
      i
    ) => {
      return (
        <Accordion
          expanded={expanded === id}
          onChange={handleChangeCb(id)}
          data-accordion-component={'AccordionItem'}
          key={i}
          data-testid="faqitem"
        >
          <AccordionSummary
            aria-controls={`${id}-content`}
            aria-expanded={expanded === id}
            id={`${type}-${id}`}
            expandIcon={
              <Icon
                name={expanded === id ? 'AccordionClose' : 'AccordionOpen'}
                color={'inherit'}
                className={classes.accordionControlIcon}
              />
            }
          >
            {faqQuestion}
          </AccordionSummary>
          <AccordionDetails>
            {!!faqAnswer && (
              <ContentfulComponentArray
                componentarray={faqAnswer}
                className={classes.quoteRoot}
                faq={true}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )
    }
  )
}

function FaqAccordion({ className, id, headline, headlineLevel, faqs, type }) {
  const classes = useStyles()
  const location = useLocation()
  const compRef = useRef(null)
  const [expanded, setExpanded] = useState('')

  // open accordion from url hash
  useEffect(() => {
    const query = queryString.parse(location.hash)[`${type}item`]
    if (query) {
      const el = compRef?.current.querySelector(`#${type}-${query}`)
      if (el) {
        setExpanded(query)
        setTimeout(() => {
          window.scrollTo({
            top: el.getBoundingClientRect().y - 150,
          })
        }, 200)
      }
    }
  }, [])

  function onItemChange(uuid, isExpanded) {
    let curPath = location.pathname
    const query = queryString.parse(location.hash)
    if (curPath && uuid.length) {
      if (isExpanded) {
        query[`${type}item`] = uuid
      } else {
        delete query[`${type}item`]
      }
      curPath += `#${queryString.stringify(query)}`
      navigate(curPath, {
        replace: true,
        state: {
          preventScroll: true,
        },
      })
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    onItemChange(panel, isExpanded)
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div ref={compRef} className={clsx(className, classes.faqRoot)} role="group">
      <Headline
        id={id}
        level={headlineLevel || 2}
        className={clsx(classes.categoryHeadline, {
          [classes.glossaryHeadline]: type === 'glossary',
        })}
      >
        {headline}
      </Headline>
      {renderSection(faqs, handleChange, expanded, classes, type)}
    </div>
  )
}

FaqAccordion.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  headline: PropTypes.string,
  headlineLevel: PropTypes.number,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  type: PropTypes.oneOf(['faq', 'glossary']),
}

FaqAccordion.defaultProps = {
  type: 'faq',
}

export default FaqAccordion
