import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  separatorRoot: {
    margin: theme.spacing(8, 0, 20, 0),
    textAlign: 'center',
    lineHeight: 0,
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(15, 0, 25, 0),
    },
  },
  img: {},
}))

function Separator() {
  const classes = useStyles()

  return (
    <div className={classes.separatorRoot}>
      <img
        className={classes.img}
        src={`/img/illustration/illustration_separator.svg`}
        alt=""
        role="separator"
      />
    </div>
  )
}

export default Separator
