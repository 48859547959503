import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import { makeStyles } from '@material-ui/core/styles'

import api from '@api'

import PostCard from '@components/postCard'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'

const useStyles = makeStyles((theme) => ({
  resultMeta: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& > *': {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  resultActions: {
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  postCount: {
    fontWeight: 500,
  },
  postCountWrapped: {
    marginBottom: theme.spacing(8),
    fontSize: '18px',
    lineHeight: '23px',
  },
  orderSelect: {
    width: '100%',
    marginBottom: (props) =>
      props.isWrapped ? theme.spacing(8) : theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: '308px',
    },
  },
  createArticle: {
    marginBottom: theme.spacing(15),
  },
  postRoot: {
    marginBottom: theme.spacing(10),
  },
  postRootLast: {
    marginBottom: theme.spacing(15),
  },
  postAnswerSingle: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(-3),
  },
  postAnswerDouble: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(15),
    marginTop: theme.spacing(-3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    margin: theme.spacing(-2, 0, 20, 0),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(-5, 15, 20, 0),
    },
    '& > a': {
      color: theme.palette.common.white,
      fontWeight: 'normal',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6.25),
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing(8),
    },
  },
}))

function SingleDialogPost({ postId }) {
  const baseLevel = 1
  const isWrapped = true
  const intl = useIntl()
  const classes = useStyles()

  const postRequest = api()
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(false)

  const getPosts = () => {
    setLoading(true)
    return postRequest
      .getPost({ id: postId })
      .then((response) => {
        setPosts([response.data.article])
        setLoading(false)
      })
      .catch((err) => {
        console.error('post query error', err)
        setLoading(false)
      })
  }
  useEffect(() => {
    getPosts()
  }, [])

  function getAnswerBorder(answers) {
    if (answers > 0) {
      return answers > 1 ? 'double' : 'single'
    }
    return 'none'
  }

  return (
    <>
      {posts.length > 0 &&
        posts.map((post, index) => {
          const answerBorder = getAnswerBorder(post.answers.length)
          const questionComponent = (
            <PostCard
              key={_.uniqueId('forum-card-')}
              className={clsx({ [classes.postQuestion]: isWrapped })}
              postData={{
                likeCount: post.numberOfLikes,
                replyCount: post.answers.length,
                userName: post.userName,
                userColor: post.userColor,
                timestamp: post.timestamp,
                isDeleted: post.isDeleted,
                deleteType: post.deleteType,
                topics: post.topics,
                title: post.title,
                text: post.text,
                id: post.id,
              }}
              isWrapped={isWrapped}
              answerBorder={answerBorder}
              link={
                !isWrapped
                  ? intl.formatMessage(
                      { id: 'forum.path.single' },
                      { postId: post.id }
                    )
                  : null
              }
              textLink={
                isWrapped
                  ? `${intl.formatMessage(
                      { id: 'forum.path.single' },
                      { postId: post.id }
                    )}?dialogueid=${post.id}`
                  : null
              }
              type={'dialogue'}
              baseLevel={baseLevel}
            />
          )
          const postAnswer = isWrapped && post.answers ? post.answers[0] : null

          const answerComponent = postAnswer ? (
            <PostCard
              key={_.uniqueId('answer-')}
              className={clsx({
                [classes.postAnswerSingle]: answerBorder === 'single',
                [classes.postAnswerDouble]: answerBorder === 'double',
              })}
              bar={'yellow'}
              background={'darkSmall'}
              postData={{
                likeCount: postAnswer.numberOfLikes,
                replyCount: postAnswer.comments.length,
                userName: postAnswer.userName,
                userColor: postAnswer.userColor,
                timestamp: postAnswer.timestamp,
                isDeleted: postAnswer.isDeleted,
                deleteType: postAnswer.deleteType,
                topics: postAnswer.topics,
                title: postAnswer.title,
                text: postAnswer.text,
                isExpert: postAnswer.isExpertAnswer,
                isEdited: postAnswer.isEdited,
                editReason: postAnswer.editReason,
                lastEditedTimestamp: postAnswer.lastEditedTimestamp,
                id: postAnswer.id,
                comments: postAnswer.comments,
              }}
              isWrapped={isWrapped}
              type={'dialogue-answer'}
              textLink={
                isWrapped
                  ? `${intl.formatMessage(
                      { id: 'forum.path.single' },
                      { postId: post.id }
                    )}?answerid=${postAnswer.id}`
                  : null
              }
              interactive={false}
              noMetaBar={true}
              baseLevel={baseLevel}
            />
          ) : null
          return (
            <div
              className={clsx(
                {
                  [classes.postRoot]: posts.length !== index + 1,
                },
                {
                  [classes.postRootLast]: posts.length === index + 1,
                }
              )}
              key={_.uniqueId('post-')}
            >
              {questionComponent}
              {!!answerComponent && answerComponent}
            </div>
          )
        })}
      {loading && posts.length === 0 && (
        <PostCard
          key={_.uniqueId('forum-card-')}
          postData={{}}
          type={'dialogue'}
          loading={true}
          baseLevel={baseLevel}
        />
      )}

      {!loading && posts.length > 0 && (
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            to={intl.formatMessage({ id: 'dialog.path' })}
            color={'red'}
            type="secondary"
          >
            {intl.formatMessage({ id: 'button.onlinedialog' })}
          </Button>
          <CreateContentButton
            className={classes.button}
            color={'outline'}
            type="secondary"
          >
            {intl.formatMessage({ id: 'button.createArticle' })}
          </CreateContentButton>
        </div>
      )}
    </>
  )
}

SingleDialogPost.propTypes = {
  postId: PropTypes.number,
}

SingleDialogPost.defaultProps = {
  postId: 0,
}

export default SingleDialogPost
