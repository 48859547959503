import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Headline from '@objects/headline'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'relative',
    padding: theme.spacing(5, 8, 8, 4),
    margin: theme.spacing(20, 0),
    backgroundColor: theme.palette.background.grey,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(10, 'auto'),
      width: 'fit-content',
    },
    [theme.breakpoints.up('sm')]: {
      overflowX: 'clip',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '110%',
      transform: 'translateX(-5%)',
    },
  },
  textblock: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(15),
    },
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      textAlign: 'center',
    },
    '& > a': {
      color: theme.palette.common.black,
      fontWeight: 'normal',
    },
  },
  illustration: {
    display: 'block',
    position: 'absolute',
    top: theme.spacing(-10),
    right: theme.spacing(-9),
    width: theme.spacing(45),
    transform: 'rotate(45deg)',
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(-35),
      right: theme.spacing(-20),
      width: theme.spacing(58),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headline: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
}))

function TeaserBlock({ headline, label, target, isExternal }) {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" direction="row" className={classes.box}>
      <img
        className={classes.illustration}
        src="/img/illustration/illustration_colored_2.svg"
        alt={''}
      />
      <div className={classes.textblock}>
        {headline && (
          <Headline level={6} className={classes.headline}>
            {headline}
          </Headline>
        )}
        {label && (
          <Button
            type={'secondary'}
            icon="TextArrow"
            target={isExternal ? '_blank' : '_self'}
            to={target}
          >
            {label}
          </Button>
        )}
      </div>
    </Grid>
  )
}

TeaserBlock.propTypes = {
  headline: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  isExternal: PropTypes.bool.isRequired,
}
export default TeaserBlock
