import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  infoBox: {
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(8, 8, 8, 4),
    margin: theme.spacing(15, 0),
    backgroundColor: theme.palette.background.grey,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(13, 15),
      margin: theme.spacing(20, 0),
    },
  },
  headline: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 8, 6, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 20, 8, 0),
    },
  },
  copy: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(10),
      },
    },
  },
  icon: {
    backgroundImage:
      'radial-gradient(ellipse at center, #000 0%,#000 65%,transparent 65%,transparent 100%)',
    color: theme.palette.yellow.main,
    borderRadius: '50%',
    lineHeight: 1,
    fontSize: theme.spacing(8),
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.spacing(11),
      width: theme.spacing(11),
      height: theme.spacing(11),
      marginRight: theme.spacing(8),
    },
  },
  illustration: {
    position: 'absolute',
    top: theme.spacing(-10),
    right: theme.spacing(-9),
    width: theme.spacing(30),
    transform: 'scale(1, -1)',
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(-19),
      right: theme.spacing(-18),
      width: theme.spacing(58),
    },
  },
}))

function InfoBox({ className, headline, copy, markdown }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.infoBox, className)}>
      <img
        className={classes.illustration}
        src="/img/illustration/illustration_colored.svg"
        alt={''}
      />
      {headline && (
        <Headline level={4} className={classes.headline}>
          <span className={classes.icon}>
            <Icon name="Info" size="inherit" />
          </span>
          {headline}
        </Headline>
      )}
      {copy &&
        copy.map((paragraph, i) => {
          return (
            <Copy key={i} className={classes.copy} parseGlossary>
              {paragraph}
            </Copy>
          )
        })}
      {markdown && <Copy className={classes.copy} markdown={markdown} />}
    </div>
  )
}

InfoBox.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.array,
  markdown: PropTypes.string,
}

export default InfoBox
