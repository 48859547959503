import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Headline from '@objects/headline'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  twoColRoot: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(11),
    },
  },
}))

function TwoColumnText({ className, id, copy }) {
  const classes = useStyles()

  return (
    <>
      <Grid className={classes.twoColRoot} container spacing={8}>
        <Grid component={Copy} parseGlossary item xs={12} md={6}>
          {copy.left}
        </Grid>
        <Grid component={Copy} parseGlossary item xs={12} md={6}>
          {copy.right}
        </Grid>
      </Grid>
    </>
  )
}

TwoColumnText.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  copy: PropTypes.shape({
    left: PropTypes.array,
    right: PropTypes.array,
  }),
}

export default TwoColumnText
