import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../button/index'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
  bubble: {
    display: 'flex',
    fontSize: '18px',
    flexDirection: 'column',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    background: theme.palette.yellow.main,
    borderRadius: theme.spacing(15),
    padding: theme.spacing(5),
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      bottom: theme.spacing(2.5),
      right: theme.spacing(-5),
      transform: 'translateX(100%)',
      width: '30%',
    },
    '&::after': {
      zIndex: '-1',
      content: '""',
      position: 'absolute',
      width: theme.spacing(7),
      height: theme.spacing(7),
      background: theme.palette.yellow.main,
      [theme.breakpoints.down('md')]: {
        transform: 'rotate(58deg)',
      },
      [theme.breakpoints.down('lg')]: {
        left: theme.spacing(13.5),
        bottom: theme.spacing(-2),
      },
      [theme.breakpoints.up('lg')]: {
        left: theme.spacing(-2),
        bottom: theme.spacing(10),
        transform: 'rotate(45deg)',
      },
    },
    '& > a': {
      color: theme.palette.common.black,
      fontSize: '14px',
      padding: theme.spacing(2.5),
    },
  },
}))

function TeaserBubble({ title }) {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <div className={classes.wrapper}>
      <div className={classes.bubble}>
        {title && <div>{title}</div>}
        <Button
          type="text"
          icon="TextArrow"
          to={intl.formatMessage({ id: 'dialog.path' })}
        >
          {intl.formatMessage({ id: 'header.cta.dialog' })}
        </Button>
      </div>
    </div>
  )
}

TeaserBubble.propTypes = {
  title: PropTypes.string,
}

export default TeaserBubble
