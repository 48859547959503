import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Img from 'gatsby-image/withIEPolyfill'
import ParallaxImage from '@objects/parallaxImage'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  imageRoot: {
    margin: theme.spacing(6, 0, 15, 0),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(10, 0, 20, 0),
    },
  },
  rightBorder: {
    borderRight: `${theme.spacing(4)}px solid ${theme.palette.grey.main}`,
    borderRadius: theme.spacing(0, 4, 4, 0),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(-4),
    },
  },
  caption: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    ...theme.typography.caption,
  },
  copyright: {
    ...theme.typography.info,
    marginLeft: theme.spacing(4),
    textAlign: 'right',
  },
  indented: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
}))

function Image({
  image,
  alt,
  description,
  copyright,
  rightBorder,
  fullwidth,
  className,
  parallax,
}) {
  const classes = useStyles()

  return (
    <div className={clsx(className, classes.imageRoot)}>
      {image && !parallax && (
        <Img
          className={clsx({ [classes.rightBorder]: rightBorder })}
          alt={alt}
          fluid={{ ...image, media: `(min-width: 0px)` }}
          backgroundColor={true}
        />
      )}
      {image && parallax && (
        <ParallaxImage
          className={clsx({ [classes.rightBorder]: rightBorder })}
          alt={alt}
          image={image}
        />
      )}
      <div
        className={clsx(classes.caption, {
          [classes.indented]: fullwidth,
        })}
      >
        {description && <div>{description}</div>}
        {copyright && <div className={classes.copyright}>{copyright}</div>}
      </div>
    </div>
  )
}

Image.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  alt: PropTypes.string,
  description: PropTypes.string,
  copyright: PropTypes.string,
  rightBorder: PropTypes.bool,
  fullwidth: PropTypes.bool,
  parallax: PropTypes.bool,
}

export default Image
