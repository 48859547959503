import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'

const useStyles = makeStyles((theme) => ({
  quoteRoot: {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(15, 0, 10, 0),
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(20),
    },
  },
  quoteRootFaq: {
    margin: theme.spacing(6, -8),
  },
  quoteInner: {
    backgroundColor: theme.palette.background.grey,
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '300px',
      padding: theme.spacing(15, 0),
    },
  },
  quoteInnerFaq: {
    backgroundColor: theme.palette.background.grey,
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      padding: theme.spacing(0),
    },
  },
  blockquote: {
    position: 'relative',
    paddingRight: theme.spacing(15),
    margin: 0,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(25),
    },
  },
  blockquoteFaq: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  center: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 38),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 61),
    },
  },
  quote: {
    margin: 0,
    ...theme.typography.quote,
    '&:before': {
      content: '"„"',
    },
    '&:after': {
      content: '"“"',
    },
  },
  quoteFaq: {
    margin: 0,

    color: '#aa1932',
    fontSize: '18px',
    lineHeight: '28px',
    '&:before': {
      content: '"„"',
    },
    '&:after': {
      content: '"“"',
    },
  },
  author: {
    display: 'block',
    marginTop: theme.spacing(6),
    ...theme.typography.caption,
  },
  illu: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(140%, -50%)',
    width: '100px',
    [theme.breakpoints.up('md')]: {
      transform: `translate(${
        parseInt(theme.container.md.replace('px', '')) / 4
      }px, -50%)`,
      width: '200px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '240px',
      transform: `translate(${
        parseInt(theme.container.lg.replace('px', '')) / 3
      }px, -50%)`,
    },
    [theme.breakpoints.up('xl')]: {
      width: '300px',
      transform: `translate(${
        parseInt(theme.container.lg.replace('px', '')) / 2.3
      }px, -50%)`,
    },
  },
}))

function Blockquote({ text, author, sidebar, faq }) {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.quoteRoot]: !faq,
        [classes.quoteRootFaq]: faq,
      })}
      data-testid={'block-quote'}
    >
      <div
        className={clsx({
          [classes.quoteInner]: !faq,
          [classes.quoteInnerFaq]: faq,
        })}
      >
        <Container
          className={classes.quoteContainer}
          sidebar={sidebar}
          type="nomargin"
          ariaLabel="arialabel.blockquote"
        >
          <blockquote
            className={clsx({
              [classes.blockquote]: !faq,
              [classes.blockquoteFaq]: faq,
              [classes.center]: !sidebar && !faq,
            })}
          >
            <p
              className={clsx({
                [classes.quote]: !faq,
                [classes.quoteFaq]: faq,
              })}
            >
              {text}
            </p>
            {!!author && <cite className={classes.author}>{author}</cite>}
          </blockquote>
        </Container>
      </div>
      {!faq && (
        <img
          className={classes.illu}
          src={`/img/illustration/illustration_colored_diagonal.svg`}
          alt=""
        />
      )}
    </div>
  )
}

Blockquote.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
  sidebar: PropTypes.bool,
  faq: PropTypes.bool,
}

export default Blockquote
