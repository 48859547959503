import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Img from 'gatsby-image/withIEPolyfill'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@objects/button'
import InfoBox from '@objects/infoBox'

const useStyles = makeStyles((theme) => ({
  videoRoot: {
    margin: theme.spacing(6, -4, 15, -4),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(10, 0, 20, 0),
    },
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: 'calc(9 / 16 * 100%)',
    overflow: 'hidden',
    '& video': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      '&:focus': {
        border: `1px solid ${theme.palette.background.focus}`,
      },
    },
  },
  thumbWrapper: {
    position: 'relative',
    color: theme.palette.yellow.main,
  },
  play: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.yellow.main,
    textAlign: 'center',
  },
  playText: {
    margin: theme.spacing(2, 0, 0, 0),
    ...theme.typography.buttonPrimary,
  },
  caption: {
    display: 'flex',
    marginTop: theme.spacing(2),
    ...theme.typography.caption,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  copyright: {
    ...theme.typography.info,
    marginLeft: 'auto',
  },
  boxCopy: {
    marginTop: theme.spacing(6),
  },
}))

function EmbeddedVideo({
  video,
  description,
  copyright,
  thumbnail,
  alt,
  boxCopy,
  title,
}) {
  const classes = useStyles()
  const [showVideo, setShowVideo] = useState(false)
  const aspectRatio = 1.778
  const videoRef = useRef(null)

  useEffect(() => {
    if (showVideo) {
      // eslint-disable-next-line no-unused-expressions
      videoRef.current?.focus()
    }
  }, [showVideo])

  return (
    <div className={classes.videoRoot}>
      {(showVideo && (
        <div className={classes.videoWrapper}>
          <video ref={videoRef} controls={true} autoPlay>
            <source src={video} />
          </video>
        </div>
      )) || (
        <div
          data-testid={'thumb'}
          className={classes.thumbWrapper}
          onClick={() => {
            setShowVideo(true)
          }}
        >
          <Img
            className={classes.thumb}
            fluid={{ ...thumbnail, aspectRatio, media: `(min-width: 0px)` }}
            backgroundColor={true}
            alt={alt}
          />
          <div className={classes.play}>
            <Button type="icon" icon="Play" />
            <p className={classes.playText}>
              <FormattedMessage id="button.play" />
            </p>
          </div>
        </div>
      )}
      <div className={classes.caption}>
        {description && <div>{description}</div>}
        {copyright && <div className={classes.copyright}>{copyright}</div>}
      </div>
      {boxCopy && (
        <InfoBox
          className={classes.boxCopy}
          headline={title}
          markdown={boxCopy}
        />
      )}
    </div>
  )
}

EmbeddedVideo.propTypes = {
  video: PropTypes.string,
  description: PropTypes.string,
  copyright: PropTypes.string,
  thumbnail: PropTypes.object,
  alt: PropTypes.string,
  boxCopy: PropTypes.string,
  title: PropTypes.string,
}

export default EmbeddedVideo
